import * as React from "react"
import { Helmet } from "react-helmet"
import styled, { ThemeProvider } from "styled-components"
import Theme from "../theme/theme"
import GlobalStyle from "../theme/global-style"
import MenuScreen from "../components/MenuScreen"
import Navigation from "../components/Navigation"
import Section from "../components/Section"
import SplitSection from "../components/SplitSection"
import Footer from "../components/Footer"
import { useState } from "react"
import { P } from "../theme/elements"
import { crownRing } from "../assets/image-urls"

const pageContent = {
  title: "Atelier Stöpler — Op Maat Gemaakte Sieraden",
}

const InnerSection = styled.div`
  padding: 0;
  max-width: 800px;
  margin: 0 auto;
`

const Img = styled.img`
  max-width: 100%;
  height: auto;
  width: 100%;
  border-radius: 4px;
  min-height: 660px;
  margin: 40px 0 0 0;
  border: 1px solid #ffbba0;
  box-shadow: 0 0 200px -120px rgb(255, 102, 55);
`

const Werkwijze = () => {
  const [isOpen, setIsOpen] = useState(false)

  const openMenu = () => setIsOpen(true)
  const closeMenu = () => setIsOpen(false)
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageContent.title}</title>
        <link rel="canonical" href="https://atelierstopler.nl" />
      </Helmet>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <MenuScreen isOpen={isOpen} closeMenu={closeMenu} />
        {isOpen ? null : (
          <React.Fragment>
            <Navigation openMenu={openMenu} isOpen={isOpen} />
            <Section title="Werkwijze">
              <InnerSection>
                <P>
                  Wilt u een sieraad laten maken, plan een persoonlijk
                  kennismakingsgesprek in. Zo heeft zij alle tijd om daarin uw
                  wensen en ideeën te bespreken. Na dat het ontwerpproces
                  afgerond is, zal ze contact op nemen over de eventuele
                  prijsopgaven. Als u akkoord gaat met het ontwerp en de prijs
                  zult u in sommige gevallen een aanbetaling plaats vinden.
                </P>
                <P>
                  Tijdens de productie fase bent u van harte welkom om te komen
                  kijken en het sieraad te passen. Sommige edelstenen kunnen
                  alleen gezet worden als het sieraad past. Mocht dit het geval
                  zijn dan neemt zij contact met u op om een pas momentje in te
                  plannen.
                </P>
                <Img src={crownRing} alt="Crown ring picture" />
              </InnerSection>
            </Section>
            <SplitSection />
            <Footer />
          </React.Fragment>
        )}
      </ThemeProvider>
    </main>
  )
}

export default Werkwijze
